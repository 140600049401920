<script setup>
import AOS from 'aos';
import 'aos/dist/aos.css'
import { onMounted } from 'vue';
const translateX = ref(0);
const handleMouseMove = (event) => {
  const { clientX } = event;
  translateX.value = clientX;
};

onMounted(() => {
  AOS.init({
    // duration: 800,
    // easing: 'ease',
    // offset: 100,
    once: false,
  });
});
</script>
<template>
  <section
    id="elevating-excellence"
    class="2xl:container m-auto"
    data-aos="fade-up"
    @mousemove="handleMouseMove"
  >
    <div
      class="transform ease-in-out transition-all duration-100"
      :style="{ transform: `translateX(${translateX / 5}px)` }"
    >
      <h2 class="footer-title !text-left">Elevating</h2>
    </div>
    <div
      class="transform ease-in-out transition-all duration-100"
      :style="{ transform: `translateX(-${translateX / 5}px)` }"
    >
      <h2 class="footer-title !text-right">Excellence</h2>
    </div>
  </section>
</template>
